import React, { useMemo, useState } from "react";
import wallet_qr from "/assets/img/wallet/wallet_qr.jpg";
import Input from "/components/core/labels/Input";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "/components/core/ButtonDefault";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import Select from "/components/core/labels/Select";
import useSWRImmutable from "swr/immutable";

const steps = [
  "Go to your wallet",
  "Select $TICO as currency to be sent",
  "Select Avalance for the network",
  "Copy and paste the following wallet address into the designated field:",
  "After checking that everything is in order, proceed with the deposit",
  "After you send the funds, you can safely close this window and wait.",
];

let sto = null;

const Title = ({ id }) => {
  return (
    <p className="df gap-6">
      <span>0{id}</span> {steps[id - 1]}
    </p>
  );
};
/**
 * @type {import("./available-chains.js").AvailableChain[]}
 */
const defaultChains = [
  {
    chainId: 1,
    chainName: "Test Avalanche 1",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
  {
    chainId: 2,
    chainName: "Test Avalanche 2",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
  {
    chainId: 3,
    chainName: "Test Avalanche 3",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
];

export default function Flow() {
  const [selectedChain, setSelectedChain] = useState("");
  const axiosClient = useAxiosWithAuth();
  /**
   *
   * @type {import("swr").SWRResponse<import("./available-chains.js").AvailableChain[], { 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }>}
   */
  const availableChains = useSWRImmutable(
    ["cashier/direct-deposit/available-chains"],
    ([url]) => axiosClient.wrap(axiosClient.get(url)),
    {
      fallbackData: defaultChains, // todo: adjust to the real fallbackData
      revalidateOnMount: true, // to revalidate fallbackData
    }
  );

  const chainList = useMemo(
    () =>
      (availableChains.data || [])
        .filter(({ chainId }) => chainId !== undefined && chainId !== null)
        .reduce((acc, { chainId, chainName }) => {
          acc[chainId] = chainName;
          return acc;
        }, {}),
    [availableChains.data]
  );

  /**
   * This address should be gained only after the user has selected the chain manually
   * It make sense to use trusted event to trigger this request, but for now it's fine
   * @type {import("swr").SWRResponse<{ address: string, memo: string|null, tag: string|null }, { 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }>}
   */
  const depositAddress = useSWRImmutable(
    () =>
      !selectedChain
        ? null
        : ["cashier/direct-deposit/deposit-address", selectedChain],
    ([url, chainId]) =>
      axiosClient.wrap(
        axiosClient.get(url, {
          params: { chainId },
        })
      )
  );

  const selectChainID = (e) => {
    setSelectedChain(e.target.value);
  };

  return (
    <>
      <p className="fixel_22 text-center">
        To deposit $TICO from your wallet <br /> follow the instructions below:
      </p>
      <div className="list-rows df fd-c fixel_16_md">
        <Title id={1} />
        <Title id={2} />
        <Title id={3} />
        {(() => {
          if (availableChains.error) {
            return (
              <div>
                <p>{availableChains.error.message}</p>
                <CustomButton
                  onClick={() => availableChains.mutate()}
                  classname={`go-back grey as-c`}
                  def={40}
                  title={"Retry"}
                />
              </div>
            );
          }
          if (!availableChains.data) {
            return (
              <Select
                handleChange={selectChainID}
                list={chainList}
                def={"Select chain"}
                value={selectedChain}
              />
            );
          }
          return (
            <Select
              handleChange={selectChainID}
              list={chainList}
              def={availableChains.data[0]?.chainName}
              value={selectedChain}
            />
          );
        })()}
        <Title id={4} />

        {(() => {
          if (depositAddress.error) {
            return (
              <AddressErrorView
                data={{
                  address: depositAddress.error.message,
                  qr: "",
                }}
              />
            );
          }
          if (depositAddress.isLoading && !depositAddress.data) {
            return (
              <AddressView
                data={{
                  address: "Loading...",
                  qr: "Loading...",
                }}
              />
            );
          }
          if (depositAddress.isValidating) {
            return (
              <AddressView
                data={{
                  address: "Loading...",
                  qr: "Loading...",
                }}
              />
            );
          }
          return <AddressView data={depositAddress.data} />;
        })()}

        <Title id={5} />
        <Title id={6} />

        {/* <CustomButton
          onClick={back}
          classname={`go-back grey as-c`}
          def={40}
          title={"Back"}
        /> */}
      </div>
    </>
  );
}

function CopyAddress({ text }) {
  const [copy, setCopy] = useState(false);
  const onCopy = () => {
    sto && clearTimeout(sto);
    setCopy(true);
    sto = setTimeout(() => setCopy(false), 1500);
  };
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <div className={"icon_copy icon-24 ar-1" + (copy ? " copyed" : "")}></div>
    </CopyToClipboard>
  );
}

function AddressView({ data }) {
  return (
    <>
      <div className="wallet-ddress df aic-">
        <Input
          readOnly={true}
          value={data?.address || "Ox"}
          name="walletAddress"
        />
        <CopyAddress text={data?.address} />
      </div>

      <div className="qr as-c df cover ar-1">
        <img src={data?.qr || ""} alt="" />
      </div>
    </>
  );
}
function AddressErrorView({ data }) {
  return (
    <>
      <div className="wallet-ddress df aic-">
        <Input
          readOnly={true}
          value={data?.address || "Ox"}
          name="walletAddress"
        />
        <CopyAddress text={data?.address} />
      </div>

      <div className="qr as-c df cover ar-1">
        <img src={data?.qr || ""} alt="" />
      </div>
    </>
  );
}
