import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LoadImage = (props) => {
  if (typeof props === "string") return;
  return props ? (
    <LazyLoadImage
      wrapperProps={{ style: { width: "100%" } }}
      {...props}
      effect="blur"
    />
  ) : null;
};

export default LoadImage;
