import React, { useRef } from "react";
import DelayLink from "/components/core/DelayLink";
import { useLocation } from "react-router-dom";
import UserRanks from "/components/core/UserRanks";
import RankLine from "/components/core/RankLine";
import UserAvatar from "/components/core/UserAvatar";
import InfoMessage from "/components/core/InfoMessage";

export default function User({ email, shortInfo, mobile, auth, info, state }) {
  const { pathname } = useLocation();
  const isProfile = pathname.includes("profile");
  const { my_rank } = auth;
  const hoverRef = useRef(null);

  const cb = isProfile ? { onClick: (e) => {} } : {};

  return (
    <div className="user_info df">
      <DelayLink to={"/profile/appearance"} className="df" {...cb}>
        <UserAvatar {...auth}>
          {/* {info && <p className="info"></p>} */}
        </UserAvatar>
      </DelayLink>

      <div className="user_info_about">
        <DelayLink
          to={"/profile/settings"}
          className="name fixel_16_sbl "
          {...cb}
        >
          {auth?.username}
        </DelayLink>
        {email ? <p className="email fixel_14 ">{auth?.email}</p> : null}
        <div ref={hoverRef}>
          <DelayLink
            to={"/profile/rank-and-progress"}
            {...cb}
            className="user_info_rang df ai-c mr-t-10"
          >
            {my_rank?.current?.iconUrl && (
              <div className="ico from df cover">
                <img src={my_rank.current.iconUrl} alt="My Rank Icon" />
              </div>
            )}
            {my_rank && <RankLine my_rank={my_rank} state={state} />}
            {my_rank?.next?.iconUrl && (
              <div className="ico to df cover">
                <img src={my_rank.next.iconUrl} alt="Next Rank Icon" />
              </div>
            )}
          </DelayLink>
        </div>
      </div>

      {shortInfo && !mobile && (
        <InfoMessage target={hoverRef}>
          <UserRanks />
        </InfoMessage>
      )}
    </div>
  );
}
