import {
  FeaturesReady,
  GrowthBook,
  GrowthBookProvider,
} from "@growthbook/growthbook-react";
import Preloader from "/components/layout/Preloader";

const featureFlags = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey:
    import.meta.env.VITE_ENVIRONMENT === "production"
      ? "sdk-hMYdrBTIA5ADYoyz"
      : "sdk-tAYzjAmyPjhMnBcJ",
  enableDevMode: import.meta.env.VITE_ENVIRONMENT !== "production",
});

featureFlags.init({ streaming: false });

export { featureFlags };

export default function FeatureFlagsProvider({ children, state }) {
  return (
    <GrowthBookProvider growthbook={featureFlags}>
      <FeaturesReady timeout={1500} fallback={<Preloader state={state} />}>
        {children}
      </FeaturesReady>
    </GrowthBookProvider>
  );
}
