import React from "react";
import DelayLink from "/components/core/DelayLink";

export default function KYC({ onClose }) {
  const goToKYC = () => {
    setTimeout(onClose, 250);
  };
  return (
    <>
      <p className="fixel_22 text-center">
        In order to be able to deposit $TICO, <br />
        you need to have completed your KYC.
      </p>
      <DelayLink
        to={"/profile/verification"}
        className="def type-40 br-16 pr as-c go-to-kyc"
        title="Complete KYC"
        cd={goToKYC}
      >
        {"Complete KYC"}
      </DelayLink>
    </>
  );
}
