import gsap from "gsap/all";
import React, { useContext, useEffect, useRef, useState } from "react";
import useAxiosREST from "/utils/hooks/useAxiosAPI";
import AuthContext from "/context/AuthProvider";
import SlotCounter from "react-slot-counter";
import { AnimatedCounter } from "react-animated-counter";

export default function UserBalance() {
  const [balance, setBalance] = useState(0.0);
  const [prev, setPrev] = useState(0);
  const balRef = useRef(null);
  const { fetchAPI } = useAxiosREST();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    return () => {};
  }, [balance]);

  // useEffect(() => {
  //   const wrap = balRef.current;

  //   let tl = gsap.timeline({
  //     defaults: {
  //       duration: 2,
  //       textContent: balance,
  //       ease: "power2.out",
  //       onUpdate: () => {
  //         wrap.style.minWidth = wrap.clientWidth + "px";
  //       },
  //     },
  //   });
  //   tl.to(wrap, { snap: { textContent: 0.01 } }, 0);

  //   return () => {
  //     tl.kill();
  //   };
  // }, [balance]);

  useEffect(() => {
    setBalance(auth.ticoBalance);
    return () => {};
  }, [auth.ticoBalance]);

  // useEffect(() => {
  //   const tl = gsap.timeline({ repeat: -1, paused: false });

  //   const getBalance = async () => {
  //     if (auth?.token) {
  //       const data = await fetchAPI.get("/user/tico-balance");
  //       const bal = data?.ticoBalance;
  //       bal && setBalance((e) => {
  //         const b = bal !== e ? bal : e;
  //         // let rand = Math.random();
  //         // rand < 0.6 && (rand = rand + 1);
  //         // setPrev(e);
  //         // const b = (e + 1) * rand;
  //         return b.toFixed(2);
  //       });
  //     }
  //   };
  //   tl.to([], { duration: 30, onComplete: getBalance });
  //   return () => {
  //     tl.kill();
  //   };
  // }, []);

  return (
    <>
      <div className="bal mr-t-8 df ai-c gap-10 text-center">
        <div className="tico_icon ico"></div>
        <AnimatedCounter
          value={balance}
          color="white"
          fontSize="16px"
          decimalPrecision={0}
          incrementColor={prev > balance ? "#dc1947" : "#b9ff54"}
          decrementColor={prev > balance ? "#dc1947" : "#b9ff54"}
          includeCommas
        />

        {/* <SlotCounter value={balance} duration={0.5} /> */}
        {/* <div ref={balRef}>0</div> */}
      </div>
    </>
  );
}
