import React, { useContext, useEffect, useState } from "react";
import AuthContext from "/context/AuthProvider";
// import Rank from "../../assets/img/icons/my_rank?.svg";
// import Star from "../../assets/img/icons/star_sm.svg";
// import rank_my from "../../assets/img/temp/rank_my.png";
// import setUserData from "./GetUserData";
export default function UserRanks({ myref }) {
  const {
    auth: { my_rank, season },
  } = useContext(AuthContext);

  if (!my_rank) return <></>;

  return (
    <div className="user_short_rang" ref={myref}>
      <div className="info df fd-c fixel_14 br-8">
        <div className="row">
          <div className="t">Current Rank</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c">
              {/* {my_rank?.current?.name} */}
              Unranked {/* REMOVE AFTER  */}
              {my_rank?.current?.iconUrl && (
                <div className="ico to df">
                  <img
                    src={my_rank?.current.iconUrl}
                    alt={my_rank?.current?.name}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="t">Next Rank</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {my_rank?.next?.name}
              {my_rank?.next?.iconUrl && (
                <div className="ico to df">
                  <img src={my_rank?.next.iconUrl} alt={my_rank?.next?.name} />
                </div>
              )}
            </div>
            <div className="df ai-c gap-4">
              {/* {my_rank?.currentXp} <span className="color-neu"> / </span>{" "} */}
              0 <span className="color-neu"> / </span> {/* REMOVE AFTER  */}
              {/* {my_rank?.next.requiredXpPoints}  */}
              TBA {/* REMOVE AFTER  */}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {/* {my_rank?.next.requiredXpPoints - my_rank?.currentXp}{" "} */}
              TBA {/* REMOVE AFTER  */}
              <div className="icon_xp xs"></div> <span>Needed</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="t">Next Prize</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {/* {my_rank?.currentXp} <span className="color-neu"> / </span>{" "} */}
              0 <span className="color-neu"> / </span> {/* REMOVE AFTER  */}
              {/* {my_rank?.next.requiredXpPoints}  */}
              TBA {/* REMOVE AFTER  */}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {/* {my_rank?.next.requiredXpPoints - my_rank?.currentXp}{" "} */}
              TBA {/* REMOVE AFTER  */}
              <div className="icon_xp xs"></div> <span>Needed</span>
            </div>
          </div>
        </div>
        <div className="line w-full"></div>

        <div className="rest df fd-c">
          <div className="row">
            <div className="t">Season</div>
            <div className="v fixel_14_sbl df fd-c">Early Adopters</div>{" "}
            {/* REMOVE AFTER  */}
            {/* <div className="v fixel_14_sbl df fd-c">{season?.seasonName}</div> */}
          </div>

          <div className="row">
            <div className="t">Level</div>
            <div className="v fixel_14_sbl df fd-c">
              {/* {my_rank?.currentLevel} */}1 {/* REMOVE AFTER  */}
            </div>
          </div>
          <div className="row">
            <div className="t">Progress</div>
            <div className="v fixel_14_sbl df fd-c">
              <div className="df ai-c gap-4">
                {/* {season?.currentXp}  */}0 {/* REMOVE AFTER  */}
                <span className="color-neu"> / </span>{" "}
                {/* {season?.requiredXpToUnlockNextLevel} */}
                TBA {/* REMOVE AFTER  */}
                <div className="icon_xp xs"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
