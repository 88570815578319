import { WagmiProvider, createConfig, http } from 'wagmi'
import { mainnet, base, polygon } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectKitProvider, getDefaultConfig } from 'connectkit'
import React, { useEffect } from 'react'

const config = createConfig(
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet, base, polygon],
    transports: {
      // RPC URL for each chain
      [mainnet.id]: http(`https://eth-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`),
      [base.id]: http(`https://base-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`),
      [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`)
    },

    // Required API Keys
    walletConnectProjectId: import.meta.env.VITE_REOWN_PROJECT_ID,

    // Required App Info
    appName: 'Funtico',

    // Optional App Info
    appDescription:
      "Funtico is a new, exciting gaming platform, that takes your favorite genres to unprecedented heights! Your skills aren't just a matter of pride; they unlock incredible prizes and exclusive perks.",
    appUrl: 'https://funtico.com',
    appIcon: 'https://funtico.com/favicon/apple-touch-icon.png'
  })
)

const queryClient = new QueryClient()

export const Web3Provider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>{children}</ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
