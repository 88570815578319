import React, { useMemo, useState } from "react";
import Input from "/components/core/labels/Input";

import CustomButton from "/components/core/ButtonDefault";
import { isValidAddress } from "/utils/utils";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import useSWR, { useSWRConfig } from "swr";
import { Balance } from "./WithdrawableBalance";
import { BigDecimal } from "./money";
import useSWRImmutable from "swr/immutable";
import Select from "/components/core/labels/Select";

const defaultChains = [
  {
    chainId: 1,
    chainName: "Test Avalanche 1",
    chainIcon: null,
    requiredConfirmations: 12,
    addressType: null,
  },
];
/**
 *
 * @param {{
 *  changePart: (path: string, queryParams: import("./queryParams").WithdrawalQueryParams) => void,
 * data: import("./queryParams").WithdrawalQueryParams,
 * isUserKYC, changeTab }} props
 * @returns
 */
export default function Flow({ changePart, isUserKYC, changeTab, data }) {
  const [amountValue, setAmount] = useState(data.amountValue ?? "");
  const [addressValue, setWalletAddress] = useState(data.addressValue ?? "");
  const [isWalletOk, setIsWalletOk] = useState(null);

  const labelPlay = {
    placeholder: "Enter Amount",
    name: "amount",
    type: "number",
    value: amountValue,
    autoComplete: "false",
    required: true,
    error: !isUserKYC,
    tip: { title: "I want to withdraw" },
    handleChange: (e) => setAmount(e.target.value),
  };

  const labelAddress = {
    placeholder: "Enter Your Wallet Address...",
    name: "cryptoAddress",
    value: addressValue,
    id: "cryptoAddress",
    autoComplete: "false",
    description: "Note: Address should be ERC-20 compliant.",
    required: true,
    error: isWalletOk == null ? "" : isWalletOk ? "" : " ",
    isOk: isWalletOk,
    minLength: 15,
    tip: { title: "...to my wallet" },
  };

  const onAddressChange = (e) => {
    const v = e.target.value;
    const isValid = v ? isValidAddress(v) : null;
    setIsWalletOk(isValid);
    setWalletAddress(v);
  };

  const axiosClient = useAxiosWithAuth();

  // todo: pre-fetch all data before navigating
  const cachedBalance = useSWRConfig().cache.get("/user/tico-balance")?.data;

  const balance = useSWR(
    () => ["cashier/balance"],
    ([url]) => axiosClient.get(url).then((res) => res.data),
    {
      fallbackData: cachedBalance
        ? {
            balance: cachedBalance,
            withdrawableBalance: "?",
            blockedBalance: "?",
          }
        : undefined,
      keepPreviousData: true,
      refreshInterval: 25000,
    }
  );

  const hasWithdrawableBalance = () => +balance.data?.withdrawableBalance > 0;

  /**
   *
   * @type {import("swr").SWRResponse<import("./available-chains.js").AvailableChain[], { 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }>}
   */
  const availableChains = useSWRImmutable(
    ["cashier/withdrawal/available-chains"],
    ([url]) => axiosClient.wrap(axiosClient.get(url)),
    {
      fallbackData: defaultChains, // todo: adjust to the real fallbackData
      revalidateOnMount: true, // to revalidate fallbackData
      keepPreviousData: true,
    }
  );

  const chainList = useMemo(
    () =>
      (availableChains.data || [])
        .filter(({ chainId }) => chainId !== undefined && chainId !== null)
        .reduce((acc, { chainId, chainName }) => {
          acc[chainId] = chainName;
          return acc;
        }, {}),
    [availableChains.data]
  );

  const [selectedChain, setSelectedChain] = useState(data.chainId ?? "");

  const selectChainID = (e) => {
    setSelectedChain(e.target.value);
  };

  return (
    <>
      <Balance {...balance} />
      <div className="buy-fiat df fd-c">
        <div className="df fd-c">
          <Input {...labelPlay}>
            <p className="right-tip">$TICO</p>
          </Input>
          <p className="fixel_14 kyc-error color-red mr-t-8">
            In order to be able to withdraw this amount of $TICO, you need a{" "}
            <span className="text-underline pointer">Verified Account.</span>
            <br />
            Please read the{" "}
            <span
              className="text-underline pointer"
              onClick={() => changeTab("info")}
            >
              Info Tab
            </span>{" "}
            for more details.
          </p>
        </div>

        <Counts
          onChange={(x) => {
            if (hasWithdrawableBalance()) {
              // todo: it should be a BigDecimal raw value
              const val = new BigDecimal(balance.data?.withdrawableBalance).mul(
                x
              );
              console.log(balance.data?.withdrawableBalance, x, val);
              setAmount(val.toFixed(0));
            }
          }}
          disabled={!hasWithdrawableBalance()}
        />
        {(() => {
          if (availableChains.error) {
            return (
              <div>
                <p>{availableChains.error.message}</p>
                <CustomButton
                  onClick={() => availableChains.mutate()}
                  classname={`go-back grey as-c`}
                  def={40}
                  title={"Retry"}
                />
              </div>
            );
          }
          if (!availableChains.data) {
            return (
              <Select
                handleChange={selectChainID}
                list={chainList}
                def={"Select chain"}
                value={selectedChain}
              />
            );
          }
          return (
            <Select
              handleChange={selectChainID}
              list={chainList}
              def={availableChains.data[0]?.chainName}
              value={selectedChain}
            />
          );
        })()}
        <Input {...labelAddress} handleChange={onAddressChange} />
      </div>

      <div className="buttons df jc-c">
        <CustomButton
          onClick={() =>
            changePart("confirm", {
              amountValue,
              addressValue,
              chain: selectedChain,
            })
          }
          classname={`go-buy pr`}
          def={40}
          disabled={!hasWithdrawableBalance() || !amountValue || !addressValue}
          title={"Withdraw"}
        />
      </div>
    </>
  );
}

/**
 * @type {[string, number][]}
 */
const counts = [
  ["1/4", 0.25],
  ["1/2", 0.5],
  ["3/4", 0.75],
  ["MAX", 1],
];

/**
 *
 * @param {{ onChange: (x: number) => void, disabled: boolean }} param0
 * @returns
 */
function Counts({ onChange, disabled }) {
  // todo: isn't it optional radio button, and we should send it to the server?
  return (
    <div className="row-counts df w-full fixel_16_sbl">
      {counts.map(([label, value]) => (
        <button
          disabled={disabled}
          className="count df ai-c br-14"
          key={label}
          onClick={() => !disabled && onChange(value)}
        >
          <div className="w-full df jc-c">
            <p>{label}</p>
          </div>
        </button>
      ))}
    </div>
  );
}
